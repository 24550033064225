<template>
  <div class="titulo-resenas">
    <h2>Los Mejores Casinos Online de Argentina</h2>
  </div>
  <div class="toplist" id="toplist">
  <div class="wrapper">
  <template v-for="item in items" :key="item['Nombre']">
  <div class="review" v-if="item.activo">
    <div class="review__inner">
      <div class="review__info-flex" v-if="!item.activo">
    <div class="h-flex" v-if="item.seguridad">
      <p class="text-center">🛡️</p>
  <circle-progress
      :percent="+(item.seguridad)"
      :is-gradient="true"
      
      :show-percent=true
      :gradient="{
        angle:0,
        startColor: '#0093fb',
        stopColor: '#002f81'
    }"
  />
    </div>
    <div class="h-flex" v-if="item.software">
    <p class="text-center">🎰</p>
  <circle-progress
      :percent="+(item.software)"
      :is-gradient="true"
      
      :show-percent=true
      :gradient="{
        angle:0,
        startColor: '#0093fb',
        stopColor: '#002f81'
    }"
  />
  </div>
  </div>
    <div class="review__image-holder">
      <img :src="item.logo" :alt="`${item.url_resena}-${item.Nombre}-logo`" class="img review__image">
    </div>
  <div class="review__content" v-if="!item.activo">
  <div class="review__info-flex">
  <div class="h-flex" v-if="item.atenc_rating">
    <p class="text-center">🛟</p>
  <circle-progress
      :percent="+(item.atenc_rating)"
      :is-gradient="true"
      
      :show-percent=true
      :gradient="{
        angle:0,
        startColor: '#0093fb',
        stopColor: '#002f81'
    }"
  />
  </div>
  <div class="h-flex" v-if="item.bonos_prom_rating">
    <p class="text-center">🤑</p>
  <circle-progress
      :percent="+(item.bonos_prom_rating)"
      :is-gradient="true"
      
      :show-percent=true
      :gradient="{
        angle:0,
        startColor: '#0093fb',
        stopColor: '#002f81'
    }"
  />
  </div>
  </div>

  </div>
  <div class="review__btn-holder">
  <div v-if="item.url"><a :href="item.url" class="review__btn btn btn--primary">
  <span class="btn-text_">Jugar en el Casino</span><!--Al casino -->
  </a></div>  
<div><a :href="item.url_resena" class="res-button gb-button-text btn-text_">
  Leer reseña <!-- A reseñas -->
  </a></div>
  
  </div>
  </div>
  </div>
  </template>
  </div>
  </div>
</template>

<script>
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
import { onlineCasinos } from  './casinos'

export default {
  name: 'App',
  setup() {
 const casinos = onlineCasinos()
 return { ...casinos }
},
  components: {
    CircleProgress,
  }
}
</script>
<style lang="css" scoped>
.h-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
